import React, {useEffect, useState} from "react";
import FixedHeader from "./components/FixedHeader";
import Home from "./components/HomePage";
import About from "./components/AboutPage";
import Portfolio from "./components/PortfilioPage";
import Contacts from "./components/Contacts";

export default function App() {
  const [fixed, setFixed] = useState(false);
  
  useEffect(() => { //функция отслеживания скролла для выведения шапки
        const handleScroll = () => {
          let scroll = window.scrollY;
          if (scroll >= 912) {
            setFixed(true);
          } else {
            setFixed(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    return(
        <>
            <FixedHeader fixed={fixed}/>
            <main>
              <Home />
              <About />
              <Portfolio />
              <Contacts />
            </main>
        </>
    );
};