import avatar from '../assets/img/avatar.png';
import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from 'react';
import {Link} from 'react-scroll';
import htmlIcon from '../assets/icons/html.svg';
import cssIcon from '../assets/icons/css.svg';
import javascriptIcon from '../assets/icons/javascript.svg';
import reactIcon from '../assets/icons/react.svg';
import tailwindIcon from '../assets/icons/tailwind.svg';
import sassIcon from '../assets/icons/sass.svg';
import gitIcon from '../assets/icons/git.svg';
import figmaIcon from '../assets/icons/figma.svg';
import BurgerMenu from '../assets/icons/burgermenu.svg';

export default function About() {
    const screenWidth = window.innerWidth;

    const [ref, inView] = useInView({
        triggerOnce: true, // Запустить только один раз при первом появлении на экране
    });

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600 && isOpen) {
                closeMenu();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen]);

    const revealAbout = {
        hidden: {
            x: -screenWidth * 0.15,
            opacity: 0,
        },
        visible: custom => ({
            x: 0,
            opacity: 1,
            transition: {delay: custom * 0.2},
        }),
    }

    const revealMenu = {
        hidden: {
            y: 0,
            scaleY: 0,
            opacity: 0,
            transformOrigin: "top",
            transition: {duration: 0.5, ease: "easeInOut"},
        },
        visible: {
            y: 0,
            scaleY: 1,
            opacity: 1,
            transformOrigin: "top",
            transition: {duration: 0.5, ease: "easeInOut"},
        },
    }

    return (
        <section className='about-page' id='about-page'>
            <header className='non-fixed-header'>
                <ul>
                    <li>
                        <Link
                            to="home-page" 
                            spy={true} 
                            smooth={true} 
                            duration={500}
                        >
                            Главная
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="about-page" 
                            spy={true} 
                            smooth={true} 
                            offset={0} 
                            duration={500}
                        >
                            Обо мне
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="portfolio-page" 
                            spy={true} 
                            smooth={true} 
                            offset={-100} 
                            duration={500}
                        >
                            Портфолио
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="contacts-page" 
                            spy={true} 
                            smooth={true} 
                            offset={-100} 
                            duration={500}
                        >
                            Контакты
                        </Link>
                    </li>
                </ul>
                <motion.button 
                    onClick={toggleMenu}
                    whileTap={{scale: 0.9}}
                >
                    <img loading='lazy' src={BurgerMenu} alt={"Иконка бургерного меню"}/>
                </motion.button>
            </header>
            <motion.nav 
                className="non-fixed-burger-menu"
                initial='hidden'
                animate={isOpen ? 'visible' : 'hidden'}
                variants={revealMenu}
            >
                <div className='non-fixed-burger-menu-container'>
                    <ul>
                        <motion.li
                            whileTap={{ scale: 0.9 }}
                            onTap={closeMenu}
                        >
                            <Link
                                to="home-page" 
                                spy={true} 
                                smooth={true} 
                                duration={500}
                            >
                                Главная
                            </Link>
                        </motion.li>
                        <motion.li
                            whileTap={{ scale: 0.95 }}
                            onTap={closeMenu}
                        >
                            <Link
                                to="about-page" 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={500}
                            >
                                Обо мне
                            </Link>
                        </motion.li>
                        <motion.li
                            whileTap={{ scale: 0.95 }}
                            onTap={closeMenu}
                        >
                            <Link
                                to="portfolio-page" 
                                spy={true} 
                                smooth={true} 
                                offset={-90} 
                                duration={500}
                            >
                                Портфолио
                            </Link>
                        </motion.li>
                        <motion.li
                            whileTap={{ scale: 0.95 }}
                            onTap={closeMenu}
                        >
                            <Link
                                to="contacts-page" 
                                spy={true} 
                                smooth={true} 
                                offset={-100} 
                                duration={500}
                            >
                                Контакты
                            </Link>
                        </motion.li>
                    </ul>
                </div>
            </motion.nav>
            <div className='avatar-desc'>
                <motion.img 
                    src={avatar} 
                    alt="изображение Раиля" 
                    loading='lazy'
                    custom={1}
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={revealAbout}
                />
                <motion.span
                    custom={2}
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={revealAbout}
                >
                    Rail_TH (Роман Константинов)
                    <br />
                    <motion.p
                        custom={3}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={revealAbout}
                    >
                        &laquo;Если ты&nbsp;не&nbsp;видишь позитива в&nbsp;своей жизни, то&nbsp;ты&nbsp;никогда не&nbsp;обретёшь настоящее счастье&raquo;
                    </motion.p>
                </motion.span>
            </div>
            <motion.div 
                className="skills"
                custom={4}
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={revealAbout}
            >
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={htmlIcon} alt="HTML icon" whileTap={{scale: 0.9}}/>
                    <span>
                        HTML
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={cssIcon} alt="CSS icon" whileTap={{scale: 0.9}}/>
                    <span>
                        CSS
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={javascriptIcon} alt="JS JavaScript icon" whileTap={{scale: 0.9}}/>
                    <span>
                        JS
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={reactIcon} alt="ReactJS icon" whileTap={{scale: 0.9}}/>
                    <span>
                        React
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={tailwindIcon} alt="Tailwind CSS icon" whileTap={{scale: 0.9}}/>
                    <span>
                        Tailwind
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={sassIcon} alt="Sass icon" whileTap={{scale: 0.9}}/>
                    <span>
                        Sass
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={gitIcon} alt="Git icon" whileTap={{scale: 0.9}}/>
                    <span>
                        Git
                    </span>
                </div>
                <div className='skill-icon'>
                    <motion.img loading='lazy' src={figmaIcon} alt="Figma icon" whileTap={{scale: 0.9}}/>
                    <span>
                        Figma
                    </span>
                </div>
            </motion.div>
        </section>
    )
};