import React from "react";
import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import './css/style.scss';

const app = ReactDOMClient.createRoot(document.getElementById("app"))
app.render(
    <StrictMode>
        <App />
    </StrictMode>
);