import {Link} from 'react-scroll';
import { motion } from 'framer-motion';
import { useState } from 'react';
import BurgerMenu from '../assets/icons/burgermenu.svg';
import Close from '../assets/icons/close.svg';

export default function FixedHeader({fixed}) {
    const screenHeight = window.innerHeight;

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const reveal = {
        hidden: {
            y: -screenHeight - 150,
            transition: {duration: 0.5, ease: "easeInOut"},

        },
        visible: {
            y: 0,
            transition: {duration: 0.5, ease: "easeInOut"},
        },
    };
    
    return(
        <>
            <header className={fixed ? 'fixed-header fixed' : 'fixed-header'}>
                <ul>
                    <motion.li
                        whileTap={{ scale: 0.9 }}
                    >
                        <Link
                            to="home-page" 
                            spy={true} 
                            smooth={true} 
                            duration={500}
                        >
                            Главная
                        </Link>
                    </motion.li>
                    <motion.li
                        whileTap={{ scale: 0.95 }}
                    >
                        <Link
                            to="about-page" 
                            spy={true} 
                            smooth={true} 
                            offset={0} 
                            duration={500}
                        >
                            Обо мне
                        </Link>
                    </motion.li>
                    <motion.li
                        whileTap={{ scale: 0.95 }}
                    >
                        <Link
                            to="portfolio-page" 
                            spy={true} 
                            smooth={true} 
                            offset={-90} 
                            duration={500}
                        >
                            Портфолио
                        </Link>
                    </motion.li>
                    <motion.li
                        whileTap={{ scale: 0.95 }}
                    >
                        <Link
                            to="contacts-page" 
                            spy={true} 
                            smooth={true} 
                            offset={-100} 
                            duration={500}
                        >
                            Контакты
                        </Link>
                    </motion.li>
                </ul>
                <motion.button 
                    onClick={toggleMenu}
                    whileTap={{scale: 0.9}}
                >
                    <img src={BurgerMenu} alt={"Иконка бургерного меню"} />
                </motion.button>
            </header>
                <motion.nav 
                    className="burger-menu"
                    initial='hidden'
                    animate={isOpen ? 'visible' : 'hidden'}
                    variants={reveal}
                >
                    <div className='burger-menu-container'>
                        <div className='burger-menu-button-container'>
                            <motion.button 
                                onClick={closeMenu}
                                whileTap={{scale: 0.9}}    
                            >
                                <img src={Close} alt="Иконка бургерного меню" />
                            </motion.button>
                        </div>
                        <ul>
                            <motion.li
                                whileTap={{ scale: 0.9 }}
                                onTap={closeMenu}
                            >
                                <Link
                                    to="home-page" 
                                    spy={true} 
                                    smooth={true} 
                                    duration={500}
                                >
                                    Главная
                                </Link>
                            </motion.li>
                            <motion.li
                                whileTap={{ scale: 0.95 }}
                                onTap={closeMenu}
                            >
                                <Link
                                    to="about-page" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={0} 
                                    duration={500}
                                >
                                    Обо мне
                                </Link>
                            </motion.li>
                            <motion.li
                                whileTap={{ scale: 0.95 }}
                                onTap={closeMenu}
                            >
                                <Link
                                    to="portfolio-page" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-90} 
                                    duration={500}
                                >
                                    Портфолио
                                </Link>
                            </motion.li>
                            <motion.li
                                whileTap={{ scale: 0.95 }}
                                onTap={closeMenu}
                            >
                                <Link
                                    to="contacts-page" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-100} 
                                    duration={500}
                                >
                                    Контакты
                                </Link>
                            </motion.li>
                        </ul>
                    </div>
                </motion.nav>
        </>
    )
};