import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";

export default function Portfolio() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    
    const reveal = {
        hidden: {
            y: 200,
            opacity: 0,

        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition: {delay: custom * 0.2},
        }),
    }
    
    return(
        <section className="portfolio-page" id="portfolio-page">
            <div className='portfolio-page-container'>
                <a href="https://github.com/Rail-TH/genagrad-website" target="_blank" rel="noopener noreferrer">
                    <motion.div 
                        className='genagrad-proj'
                        custom={1}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={reveal}
                        whileTap={{scale: 0.98}}
                        loading='lazy'
                    >
                        <div>
                            Сайт города в Minecraft
                        </div>
                    </motion.div>
                </a>
                <a href="https://railth-website-card.vercel.app/" target="_blank" rel="noopener noreferrer">
                    <motion.div 
                        className='old-website-proj'
                        custom={2}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={reveal}
                        whileTap={{scale: 0.98}}
                        loading='lazy'
                    >
                        <div>
                            Старый сайт-портфолио
                        </div>
                    </motion.div>
                </a>
                <a href="https://www.behance.net/gallery/205743445/ForStore" target="_blank" rel="noopener noreferrer">
                    <motion.div 
                        className='forstore-proj'
                        custom={3}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={reveal}
                        whileTap={{scale: 0.98}}
                        loading='lazy'
                    >
                        <div>
                            Дизайн-макет ForStore
                        </div>
                    </motion.div>
                </a>
                <a href="https://github.com/Rail-TH/SusMarket" target="_blank" rel="noopener noreferrer">
                    <motion.div 
                        className='SusMarket-proj'
                        custom={3}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={reveal}
                        whileTap={{scale: 0.98}}
                        loading='lazy'
                    >
                        <div>
                            Интернет-магазин SusMarket
                        </div>
                    </motion.div>
                </a>
            </div>
        </section>
    )
};