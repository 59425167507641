import { motion } from 'framer-motion';
import {Link} from 'react-scroll';

export default function Home() {
    const revealText = {
        hidden: {
            opacity: 0,

        },
        visible: {
            opacity: 1,
            transition:{duration: 1}
        },
    };

    const variants = {
        hidden: { x: -100, opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { delay: 0.5 } },
    };
    
    return(
        <section className="home-page" id='home-page'>
            <div>
                <motion.h1
                    initial='hidden'
                    animate='visible'
                    variants={revealText}
                >
                    Привет, меня зовут <span>Роман Константинов</span>.
                <br />
                Я Веб-дизайнер и Фронтенд-разработчик
                </motion.h1>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                >
                    <Link
                        to="about-page" 
                        spy={true} 
                        smooth={true} 
                        duration={500}
                    >
                        Обо мне
                    </Link>
                </motion.div>
            </div>
        </section>
    )
};